import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "@/assets/scss/material-kit.scss";
import "@/assets/demo.css";
import globalDirectives from "./globalDirectives";
import globalMixins from "./globalMixins";
import basicMixins from "./basicMixins";
import globalComponents from "./globalComponents";
import Notifications from "@/components/NotificationPlugin";

export default {
  install(Vue) {
    Vue.use(VueMaterial);
    Vue.use(globalDirectives);
    Vue.use(globalMixins);
    Vue.use(basicMixins);
    Vue.use(globalComponents);
    Vue.use(Notifications);

  }
};
