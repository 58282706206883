import Vue from "vue";

import App from "./App.vue";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import VueCookies from "vue-cookies";
import store from "./store";
import MaterialKit from "./plugins/material-kit";
import JsonExcel from "vue-json-excel";
import DrVueEcharts from "dr-vue-echarts";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
locale.use(lang);

import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
Vue.use(VueVideoPlayer)

Vue.use(DrVueEcharts);
Vue.component("downloadExcel", JsonExcel);

Vue.use(VueCookies);
Vue.use(MaterialKit);

import Viewer from "v-viewer";
import VueViewer from 'v-viewer'
Vue.use(Viewer);
Vue.use(VueViewer);

Vue.mixin({
  methods: {
    localeLabel(label) {
      return Locale.localeText(label);
    },
    notifyVue(type = "default", text) {
      if (!text) {
        if (type === "success") {
          text = "Əməliyyat uğurla yekunlaşdı";
        } else if (type === "danger") {
          text = "Səhv əməliyyat";
        }
      }
      this.$notify({
        timeout: 2500,
        message: text,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: type,
      });
    },
  },
});
new Vue({
  el: "#app",
  render: (h) => h(App),
  store,
  router,
  beforeCreate() {
    this.$store.commit("initialiseStore");
  },
});
