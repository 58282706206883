import { EncryptAES, DecryptAES } from "./crypto";
import store from "../store";

const ulsname = "_carauls";
const utkn = "_carautk";
const lngckname = "_carln";
const defaultlng = "AZ";
const sessckname = "_carsess";
const sessexp = "30d";
const remckname = "_carrm";

function SetUser(user) {
  let token = "";
  if (!user) {
    localStorage.removeItem(ulsname);
  } else {
    let tk = LoadCookie(utkn);
    if (tk) {
      localStorage.setItem(ulsname, EncryptAES(JSON.stringify(user), tk));
    }
    store.state.user = user;
  }
  return;
}

function LoadUser() {
  if (localStorage.getItem(ulsname) !== null) {
    try {
      let tk = LoadCookie(utkn);
      let u = JSON.parse(DecryptAES(localStorage.getItem(ulsname), tk));
      
      return u;
    } catch (err) {
      return err;
    }
  }
}

function SetLang(lang) {
  SetCookie(lngckname, lang);
}

function LoadLang(lang) {
  var l = LoadCookie(lngckname);
  if (l) return l;
  else return defaultlng;
}

function SetUserToken(tkn) {
  SetCookie(utkn, tkn);
  return;
}

function SetRememberMe(value) {
  SetCookie(remckname, value);
  return;
}

function GetRememberMe() {
  return LoadCookie(remckname);
}

function SetCookie(name, value, exp) {
  if (exp === undefined) exp = sessexp;
  $cookies.set(name, value, exp);
  return;
}

function SetSession(remember, tkn) {
  let expire = remember ? sessexp : 0;
  SetCookie(sessckname, tkn, expire);
  return;
}

function GetSession() {
  return $cookies.get(sessckname);
}

function RemoveSession() {
  $cookies.remove(sessckname);
}

function LoadCookie(name) {
  if (!$cookies) return;
  if ($cookies.isKey(name)) return $cookies.get(name);
}

function SaveStatePlugin(store) {
  store.subscribe((mutation, state) => {
    SetUser(state.user);
    SetLang(state.lang);
  });
}

function AuthUser(user, remember, utk, stk) {
  SetUserToken(utk);
  SetUser(user);
  SetRememberMe(remember);
  SetSession(remember, stk);
}

export {
  GetRememberMe,
  SetUser,
  LoadUser,
  SaveStatePlugin,
  LoadLang,
  GetSession,
  RemoveSession,
  AuthUser,
  SetCookie,
  LoadCookie
};
