import axios from "axios";
import { api } from "./helper";
import store from "../store";
import router from "../router";
const client = axios.create({
  baseURL: api,
  withCredentials: false
});

client.interceptors.request.use((config) => {
  if (store.state.user)
    config.headers.Authorization = "Bearer " + store.state.user.jwt;
  return config;
});

client.interceptors.response.use(
  (response) => {
    if (!response.data.result)
      if (response.data.indexOf("Unauthorized") > -1) {
        router.push("/logout");
      }
    return response;
  },
  (error) => {
    console.log(error)
    if (error.response.status === 401) router.push("/logout");
    return { data: error.message };
    // return Promise.reject(error.response);
  }
);

function EmailByResetKey(resetkey) {
  const params = {
    resetkey: resetkey,
    action: "resetkeyemail"
  };
  return client.get("users.php", { params });
}

function Reset(user) {
  user.action = "reset";
  return client.put("users.php", user);
}

function SendResetEmail(user) {
  user.action = "resetemail";
  return client.post("users.php", user);
}

function UserIdByEmail(user) {
  const params = {
    email: user.email,
    action: "idbyemail"
  };
  return client.get("users.php", { params });
}

function GetUser(id) {
  const params = {
    id: id,
    action: "userbyid"
  };
  return client.get("users.php", { params });
}

function Update(user) {
  user.action = "edit";
  user.uid = store.state.user.id;
  return client.put("users.php", user);
}

function UpdateSystem(user) {
  user.action = "editsys";
  user.uid = store.state.user.id;
  return client.put("users.php", user);
}

function Create(user) {
  user.action = "create";
  user.uid = store.state.user.id;
  return client.post("users.php", user);
}

function GetUsers(uid) {
  const params = {
    action: "users",
    uid: uid,
  };
  return client.get("users.php", { params });
}

function Delete(id) {
  const params = {
    id: id,
    uid: store.state.user.id,
  };
  return client.delete("users.php", { params });
}


function Auth(username, pwd) {
  const params = {
    username: username,
    pwd: pwd,
    action: "auth"
  };
  return client.get("users.php", { params });
}

function ChangePassword(pwd) {
  pwd.action = "changepwd";
  pwd.uid = store.state.user.id;
  return client.put("users.php", pwd);
}

function GetLocations(userid) {
  const params = {
    id: userid,
    action: "locall"
  };
  return client.get("locations.php", { params });
}

function UpdateLocation(loc) {
  return client.put("locations.php", loc);
}

function DeleteLocation(id, userid) {
  const params = {
    id: id,
    userid: userid
  };
  return client.delete("locations.php", { params });
}

function CreateLocation(loc) {
  loc.action = "create";
  return client.post("locations.php", loc);
}

function UpdateLocationCoord(coord) {
  coord.action = "coord";
  return client.put("locations.php", coord);
}


export {
  EmailByResetKey,
  Reset,
  SendResetEmail,
  UserIdByEmail,
  Auth,
  GetUser,
  Update,
  ChangePassword,
  Create,
  Delete,
  GetLocations,
  UpdateLocation,
  CreateLocation,
  DeleteLocation,
  UpdateLocationCoord,
  UpdateSystem,
  GetUsers
};
