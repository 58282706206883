import CryptoJS from "crypto-js";
function EncryptDES(text) {
  var enc = CryptoJS.DES.encrypt(text, "").toString();
  var enc64 = CryptoJS.enc.Base64.parse(enc);
  var encHex = enc64.toString(CryptoJS.enc.Hex);
  return encHex;
}

function DecryptDES(text) {
  var reb64 = CryptoJS.enc.Hex.parse(text);
  var bytes = reb64.toString(CryptoJS.enc.Base64);
  var decrypt = CryptoJS.DES.decrypt(bytes, "");
  var res = decrypt.toString(CryptoJS.enc.Utf8);
  return res;
}

function EncryptAES(text, pass) {
  var salt = CryptoJS.lib.WordArray.random(128 / 8);

  var key = CryptoJS.PBKDF2(pass, salt, {
    keySize: 256 / 32,
    iterations: 100
  });

  var iv = CryptoJS.lib.WordArray.random(128 / 8);

  var encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });
  var transitmessage = salt.toString() + iv.toString() + encrypted.toString();
  return transitmessage;
}

function DecryptAES(text, pass) {
  if (!text) return;
  var salt = CryptoJS.enc.Hex.parse(text.substr(0, 32));
  var iv = CryptoJS.enc.Hex.parse(text.substr(32, 32));
  var encrypted = text.substring(64);

  var key = CryptoJS.PBKDF2(pass, salt, {
    keySize: 256 / 32,
    iterations: 100
  });

  var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

function Encrypt(text, salt) {
  if (salt === null)
    salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
  var key = CryptoJS.PBKDF2(text, salt, {
    hasher:CryptoJS.algo.SHA256,
    keySize: 128 / 8,
    iterations: 100
  });
  var pwd = {};
  pwd.key = key.toString(CryptoJS.enc.Hex);
  pwd.salt = salt;
  return pwd;
}

function GeneratePwd() {
  return CryptoJS.lib.WordArray.random(128 / 8)
    .toString(CryptoJS.enc.Hex)
    .substring(1, 6);
}

function GenerateToken() {
  return CryptoJS.lib.WordArray.random(512 / 8).toString(CryptoJS.enc.Hex);
}

function GenerateHex() {
  return CryptoJS.lib.WordArray.random(64 / 8).toString(CryptoJS.enc.Hex);
}

export {
  Encrypt,
  GeneratePwd,
  GenerateToken,
  EncryptAES,
  DecryptAES,
  GenerateHex,
  EncryptDES,
  DecryptDES
};
