import axios from "axios";
import { api } from "./helper";
import store from "../store";
import router from "../router";
const client = axios.create({
  baseURL: api,
  withCredentials: false,
});

client.interceptors.request.use((config) => {
  config.headers.Authorization = "Bearer " + store.state.user.jwt;
  return config;
});

client.interceptors.response.use(
  (response) => {
    if (!response.data.result)
      if (response.data.indexOf("Unauthorized") > -1) {
        router.push("/logout");
      }
    return response;
  },
  (error) => {
    if (error.response)
      if (error.response.status === 401) router.push("/logout");
    return { data: error.message };
    // return Promise.reject(error.response);
  }
);

function Create(file) {
  return client.post("files.php", file);
}

function GetFiles(sourcetype, sourceId, sourcedetId, userId) {
  const params = {
    action: "files",
    sourcetype: sourcetype,
    sourceId: sourceId,
    sourcedetId: sourcedetId,
    uid: userId,
  };
  return client.get("files.php", { params });
}

function Delete(id, userId) {
  const params = {
    id: id,
    uid: userId,
  };
  return client.delete("files.php", { params });
}

export { Create, GetFiles, Delete };
