import Vue from "vue";
import Vuex from "vuex";
import {
  LoadUser,
  SaveStatePlugin,
  LoadLang,
  LoadCookie,
  GetSession,
  RemoveSession,
} from "./services/localstate";
import { Update } from "./services/user";
import { Create } from "./services/files";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [SaveStatePlugin],
  state: {
    user: {},
    jwt: "",
    lang: "",
  },
  mutations: {
    UPDATE_USER(state, user) {
      state.user = user;
    },
    UPDATE_BKG(state, f) {
      state.user.bkgimage = f;
    },
    LOGOUT_USER(state) {
      state.user = null;
      state.jwt = null;
    },
    initialiseStore(state) {
      state.user = LoadUser();
      state.lang = LoadLang();
    },
    SET_LANG(state, lang) {
      state.lang = lang;
    },
  },
  actions: {
    updateProfileData({ commit }, user) {
      return Update(user).then((response) => {
        if (response.data.result) {
          commit("UPDATE_USER", user);
          return true;
        } else return false;
      });
    },
    updateProfileBkg({ commit }, f) {
      return Create(f).then((response) => {
        if (response.data) {
          commit("UPDATE_BKG", new Date().getFullYear() + "/" + f.filename);
          return true;
        } else return false;
      });
    },
    logout({ commit }) {
      commit("LOGOUT_USER");
      RemoveSession();
    },
    setLang({ commit }, lang) {
      commit("SET_LANG", lang);
    },
  },
  getters: {
    jwt: (state) => {
      if (state.user) {
        return state.user.jwt;
      } else {
        return "NA";
      }
    },
  },
});
