const api = "https://api.hucarep.com/v1/controller"
// const api =
//   process.env.NODE_ENV === "production"
//     ? "https://api.hucarep.com/v1/controller"
//     : "http://localhost:7760/controller";
const smsapi = "https://smsapi.huestimates.net/SendSms";
function ShowLink(value) {
  var text = value;
  var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  var text1 = text.replace(exp, "<a href='$1'>$1</a>");
  var exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  return text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
}

function HtmlNewLine(value) {
  return value.replace(/(?:\r\n|\r|\n)/g, "<br />");
}

function Round2(num) {
  return Math.round(num * 100) / 100;
}

function DaysBetween(dt1, dt2) {
  var oneday = 1000 * 60 * 60 * 24;
  dt1 = new Date(dt1);
  dt2 = new Date(dt2);
  var diff = Math.round(dt2.getTime() - dt1.getTime()) / oneday;

  var res = diff.toFixed(0);
  return res;
}

function DateToString(dt) {
  dt = new Date(dt);
  let date = ("0" + dt.getDate()).slice(-2);

  let month = ("0" + (dt.getMonth() + 1)).slice(-2);

  let year = dt.getFullYear();

  return year + "-" + month + "-" + date;
}

function DateTimeToString(dt) {
  dt = new Date(dt);
  let date = ("0" + dt.getDate()).slice(-2);

  let month = ("0" + (dt.getMonth() + 1)).slice(-2);

  let year = dt.getFullYear();

  let hour = dt
    .getHours()
    .toString()
    .padStart(2, "0");

  let minutes = dt
    .getMinutes()
    .toString()
    .padStart(2, "0");

  let seconds = dt
    .getSeconds()
    .toString()
    .padStart(2, "0");

  return (
    year + "-" + month + "-" + date + " " + hour + ":" + minutes + ":" + seconds
  );
}

function AddMonths(date, months) {
  var d = date.getDate();
  date.setMonth(date.getMonth() + months);
  if (date.getDate() != d) {
    date.setDate(0);
  }
  return date;
}

function StatusColor(val) {
  switch (val) {
    case 1:
      return "text-default";
    case 2:
      return "text-danger";
    case 3:
      return "text-info";
    case 4:
      return "text-warning";
    case 5:
      return "text-gray";
    case 6:
    case 7:
      return "text-success";
    default:
      return "text-default";
  }
}

function MonthName(month) {
  const monthNames = [
    "Yanvar",
    "Fevral",
    "Mart",
    "Aprel",
    "May",
    "İyun",
    "İyul",
    "Avqust",
    "Sentyabr",
    "Oktyabr",
    "Noyabr",
    "Dekabr"
  ];
  return monthNames[month];
}

function NumberFormat(val, digits) {
  return val.toLocaleString(
    "ru-RU", // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: digits }
  );
}

export {
  ShowLink,
  HtmlNewLine,
  api,
  smsapi,
  Round2,
  DaysBetween,
  DateToString,
  DateTimeToString,
  AddMonths,
  StatusColor,
  MonthName,
  NumberFormat,
};
