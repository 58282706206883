import Vue from "vue";
import Router from "vue-router";

const Login = () => import("./views/Login.vue");
const Error = () => import("./views/Error.vue");
const Reset = () => import("./views/Reset.vue");
const Profile = () =>
  import("./views/Profile.vue");
const ChangePwd = () =>
  import("./views/ChangePwd.vue");

const Contract = () =>
  import("./views/insurance/Contract.vue");
const Referrals = () =>
  import("./views/insurance/Referrals.vue");
const Main = () =>
  import("./views/insurance/Main.vue");
const Referral = () =>
  import("./views/insurance/Referral.vue");
const Reports = () =>
  import("./views/insurance/Reports.vue");

const ScReferrals = () =>
  import("./views/center/Referrals.vue");
const ScReferral = () =>
  import("./views/center/Referral.vue");
const ScMain = () =>
  import("./views/center/Main.vue");
const Schedule = () =>
  import("./views/center/Schedule.vue");
const ServiceReferrals = () =>
  import("./views/center/ServiceReferrals.vue");
const ServiceReferral = () =>
  import("./views/center/ServiceReferral.vue");
const PartnerReferral = () =>
  import("./views/center/PartnerReferral.vue");

const ServiceCenter = () =>
  import("./views/system/ServiceCenter.vue");
const Org = () => import("./views/system/Org.vue");
const User = () =>
  import("./views/system/User.vue");

import MainNavbar from "./layout/MainNavbar.vue";
import CenterNavbar from "./layout/CenterNavbar.vue";
import SystemNavbar from "./layout/SystemNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";
import store from "./store";
import { GetSession, LoadUser } from "./services/localstate";

Vue.use(Router);

const router = new Router({
  // mode: 'history',
  routes: [
    {
      path: "/",
      name: "login",
      alias: "/login",
      components: { default: Login },
    },
    {
      path: "/reset/:resetkey?",
      name: "reset",
      props: { default: true },
      components: { default: Reset },
    },

    //System routes
    {
      path: "/system/profile/:id?",
      name: "system-profile",
      components: {
        default: Profile,
        header: SystemNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 3,
      },
    },
    {
      path: "/system/change",
      name: "system-change",
      components: {
        default: ChangePwd,
        header: SystemNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 3,
      },
    },
    {
      path: "/system/servicecenters",
      name: "system-servicecenters",
      components: {
        default: ServiceCenter,
        header: SystemNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
      },
      meta: {
        requiresAuth: true,
        role: 3,
      },
    },
    {
      path: "/system/orgs",
      name: "system-orgs",
      components: {
        default: Org,
        header: SystemNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
      },
      meta: {
        requiresAuth: true,
        role: 3,
      },
    },
    {
      path: "/system/users",
      name: "system-users",
      components: {
        default: User,
        header: SystemNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
      },
      meta: {
        requiresAuth: true,
        role: 3,
      },
    },

    // Insurance routes
    {
      path: "/insurance/profile/:id?",
      name: "insurance-profile",
      components: {
        default: Profile,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 1,
      },
    },
    {
      path: "/insurance/change",
      name: "insurance-change",
      components: {
        default: ChangePwd,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 1,
      },
    },
    // Referrals
    {
      path: "/insurance/main",
      name: "insurance-main",
      components: {
        default: Main,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 1,
      },
    },
    {
      path: "/insurance/referrals/:id?",
      name: "insurance-referrals",
      components: {
        default: Referrals,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 1,
      },
    },
    {
      path: "/insurance/referral/:id?",
      name: "insurance-referral",
      components: {
        default: Referral,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 1,
      },
    },
    {
      path: "/insurance/contract",
      name: "insurance-contract",
      components: {
        default: Contract,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
      },
      meta: {
        requiresAuth: true,
        role: 1,
      },
    },
    {
      path: "/insurance/reports",
      name: "insurance-reports",
      components: {
        default: Reports,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 1,
      },
    },
    // Center routes
    {
      path: "/center/profile/:id?",
      name: "center-profile",
      components: {
        default: Profile,
        header: CenterNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 2,
      },
    },
    {
      path: "/center/change",
      name: "center-change",
      components: {
        default: ChangePwd,
        header: CenterNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 2,
      },
    },
    {
      path: "/center/main",
      name: "center-main",
      components: {
        default: ScMain,
        header: CenterNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 2,
      },
    },
    {
      path: "/center/schedule",
      name: "schedule",
      components: {
        default: Schedule,
        header: CenterNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 2,
      },
    },
    {
      path: "/center/referrals/:id?",
      name: "center-referrals",
      components: {
        default: ScReferrals,
        header: CenterNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 2,
      },
    },

    {
      path: "/center/referral/:id?",
      name: "center-referral",
      components: {
        default: ScReferral,
        header: CenterNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 2,
      },
    },
    {
      path: "/center/partnerreferral/:id?",
      name: "partner-referral",
      components: {
        default: PartnerReferral,
        header: CenterNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 2,
      },
    },
    // ScReferrals
    {
      path: "/center/screferrals/",
      name: "center-screferrals",
      components: {
        default: ServiceReferrals,
        header: CenterNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 2,
      },
    },
    {
      path: "/center/screferral/:id?",
      name: "center-screferral",
      components: {
        default: ServiceReferral,
        header: CenterNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        default: true,
      },
      meta: {
        requiresAuth: true,
        role: 2,
      },
    },

    {
      path: "/logout",
      name: "Logout",
    },
    { path: "*", component: Error },
  ],
  scrollBehavior: (to, from) => {
    if (to.name === from.name) {
      if (to.hash) {
        return { selector: to.hash };
      }
    } else if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const metaauth = to.matched.find((record) => record.meta.requiresAuth);
  if (to.path === "/logout") {
    store.dispatch("logout");
    next({
      path: "/login",
    });

    return;
  }

  if (
    !GetSession() &&
    to.path !== "/login" &&
    to.path.indexOf("/reset") === -1
  ) {
    next({
      path: "/login",
    });
    return;
  }

  if (metaauth !== undefined) {
    let user = store.state.user;
    if (user === null) {
      next({
        name: "login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      if (!user.roleid) {
        user = LoadUser();
      }
      if (user.roleid === metaauth.meta.role) next();
      else {
        next({
          name: "login",
          params: { nextUrl: to.fullPath },
        });
      }
    }
  } else next();
});

export default router;
