<template>
  <footer
    class="footer"
    :class="{
      [`footer-${type}`]: type,
      [`footer-${size}`]: size
    }"
    :data-background-color="backgroundColor"
  >
    <template v-if="$route.name !== 'products' && $route.name !== 'presentation'">
      <div class="container">
        <nav>
          <ul>
            <li>
              <a href="#">HU CaRep</a>
            </li>
          </ul>
        </nav>
        <div class="copyright">
          &copy; {{ year }}, made by
          <a href="https://huestimates.com" target="_blank">HU Estimates</a>
        </div>
      </div>
    </template>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
    size: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
